<template>
  <div class="container">
    <empty-product :productModel="product" @update="updateDebounce" />
    <!-- <div class="grid grid-cols-12 gap-4">
      <div class="col-span-4">
        <div class="w-full h-40 rounded-md bg-gray-200 relative">
          <!- <q-fab class="bottom-0 ml-2 mb-2"></q-fab> --
          <img
            :src="product.product_photo"
            class="w-full h-full object-cover rounded-md"
            v-if="!!product.product_photo"
          />
          <input
            type="file"
            ref="productImage"
            class="hidden"
            accept="image/*"
            @change="uploadProduct"
          />
          <button
            class="absolute  bottom-0 ml-2 mb-2 w-8 h-8 rounded-full bg-secondary-500 hover:bg-secondary-600 text-white shadow-md"
            @click="$refs.productImage.click()"
          >
            <i class="mdi mdi-plus text-xl"></i>
          </button>
        </div>
      </div>
      <div class="col-span-6 flex flex-col">
        <input
          v-model="product.product_name"
          placeholder="Product title"
          class="font-bold text-xl"
        />

      </div>
      <div class="col-span-2 flex flex-col">
        <h4 class="text-secondary-500">Prezzo</h4>
        <input
          class="font-bold text-2xl"
          :value="product.single_unit_price | price"
          @blur="product.single_unit_price = parseFloat($event.target.value)"
          placeholder="€ 0.00"
        />
        <div class="flex-grow">&nbsp;</div>
        <h4 class="text-secondary-500">Pulsante</h4>
        <button class="btn btn-cta">Acquista</button>
      </div>
    </div> -->
    <!-- <div class="w-full">
      <h2 class="text-secondary-500 mt-4">Informazioni Aggiuntive</h2>
      <vue-editor
        v-model="product.product_description"
        :editor-toolbar="customToolbar"
      ></vue-editor>
    </div> -->
    <div class="w-full my-4 flex">
      <div class="w-1/4 mr-4">
        <q-button
          class="w-full"
          :cta="true"
          @click="updateProduct"
          :loading="isCreating"
        >
          Aggiorna
        </q-button>
      </div>
      <button
        class="py-3 px-8 rounded-md text-red-500 border border-red-500"
        @click="removeProduct($route.params.id)"
      >
        Cancella
      </button>
      <p>{{ creatingStatus }}</p>
    </div>
    <!-- <div class="flex my-6">
      <button
        class="bg-gray-200 hover:bg-gray-300 flex justify-center items-center w-16 h-16 rounded-full"
      >
        <div class="i mdi mdi-plus text-3xl text-gray-400"></div>
      </button>
      <div class="flex flex-col ml-8">
        <h1 class="text-gray-300">Aggiungi un nuovo prodotto</h1>
        <div class="p text-red-300">
          Potrai aggiungere un proddoto per volta. Questa funzione verrà
          sbloccata dopo la pubblicazione.
        </div>
      </div>
    </div> -->
  </div>
</template>

<script>
import { API_URL } from "@/app-axios";
import { v4 as uuidv4 } from "uuid";
import Axios from "axios";
import { mapActions } from "vuex";
import EmptyProduct from "./_components/EmptyProduct.vue";
// import QFab from "@/_components/QFab";

export default {
  // props: {
  //   product: {
  //     type: Object,
  //     default: () => {}
  //   }
  // },
  components: {
    EmptyProduct
    // QFab
  },
  data() {
    return {
      product: {},
      title: "",
      description: "",
      note: "",
      file: null,
      isCreating: false,
      creatingStatus: "",
      filePath: "img%2Fproduct%2F",
      customToolbar: [
        ["bold", "italic", "underline"],
        [{ list: "ordered" }, { list: "bullet" }],
        ["code-block"]
      ]
    };
  },
  async mounted() {
    let prods = await API_URL({
      url: `/products/${this.$route.params.id}`
    });

    this.product = prods.data;
  },
  computed: {},
  methods: {
    ...mapActions("products", ["publishProduct", "deleteProduct"]),

    updateDebounce(e) {
      console.log("Debounced Update", e);
      this.product = e;
    },
    async uploadProduct(e) {
      // console.log("CARICO FOTO PODOTTO", e.target.files[0]);
      this.file = e.target.files[0];
      try {
        let filename = this.file.name.split(".");
        let ext = filename[filename.length - 1];
        let options = {
          url: `${process.env.API_URL}/uploads/${
            this.filePath
          }${uuidv4()}.${ext}`,
          method: "PUT",
          headers: {
            "Content-Type": this.file.type
          },
          data: this.file
        };
        console.log("Options ", options);
        let res = await Axios(options);
        setTimeout(() => {
          this.product.product_photo(res.data.url);
        }, 3500);
        console.log("Prodotto creato ", res);
      } catch (error) {
        console.log("Errore Upload photo", JSON.parse(JSON.stringify(error)));
      }
    },
    async updateProduct() {
      try {
        this.isCreating = true;
        let res = await API_URL({
          method: "PUT",
          url: `/products/${this.$route.params.id}`,
          headers: {
            "aws-access-token": this.$store.getters["auth/token"]
          },
          data: this.product
        });
        console.log("Body  ", this.product);
        console.log("Modifica effettuata con successo ", res);
      } catch (error) {
        console.log("Errore nella creazione del prodotto", error);
        this.creatingStatus = "Errore nella creazione del prodotto";
      } finally {
        setTimeout(() => {
          this.creatingStatus = "";
        }, 5000);
        this.isCreating = false;
      }
    },
    removeProduct(id) {
      this.deleteProduct(id);
      this.$router.go(-1);
    }
  }
};
</script>

<style></style>
