<template>
  <div class="container">
    <div class="grid grid-cols-12 gap-4">
      <div class="col-span-4">
        <div class="w-full h-40 rounded-md bg-gray-200 relative">
          <!-- <q-fab class="bottom-0 ml-2 mb-2"></q-fab> -->
          <img
            :src="productModel.product_photo"
            class="w-full h-full object-cover rounded-md"
            v-if="!!productModel.product_photo"
          />
          <input
            type="file"
            ref="productImage"
            class="hidden"
            accept="image/*"
            @change="uploadProduct"
          />
          <button
            class="absolute  bottom-0 ml-2 mb-2 w-8 h-8 rounded-full bg-secondary-500 hover:bg-secondary-600 text-white shadow-md"
            @click="$refs.productImage.click()"
          >
            <i class="mdi mdi-plus text-xl"></i>
          </button>
        </div>
      </div>
      <div class="col-span-6 flex flex-col">
        <input
          v-model="productModel.product_name"
          placeholder="Product title"
          class="font-bold text-xl"
        />
        <textarea
          v-model="productModel.product_description"
          placeholder="Product description"
          rows="2"
          maxlength="250"
          class="flex-grow"
        ></textarea>
        <span class="text-gray-400 text-xs w-full text-right"
          >{{ productModel.product_description.length }}/250</span
        >
        <!-- <v-date-picker
          locale="it"
          is24hr
          :v-model="s_service_start_date"
          :value="s_service_start_date"
          mode="dateTime"
          color="teal"
          class="mt-4"
        >
          <template v-slot="{ inputValue, inputEvents }">
            <label>Data evento</label>
            <input
              class="bg-white border px-2 py-1 rounded w-full"
              :value="inputValue"
              v-on="inputEvents"
            />
          </template>
        </v-date-picker> -->
      </div>
      <div class="col-span-2 flex flex-col">
        <h4 class="text-secondary-500">Prezzo</h4>
        <input
          class="font-bold text-2xl"
          :value="productModel.single_unit_price | price"
          @blur="productModel.single_unit_price = $event.target.value"
          placeholder="€ 0.00"
          maxlength="12"
        />
        <div class="flex-grow">&nbsp;</div>
        <h4 class="text-secondary-500">Pulsante</h4>
        <button class="btn btn-cta">Acquista</button>
      </div>
    </div>
    <div class="w-full">
      <h2 class="text-secondary-500 mt-4">Informazioni Aggiuntive</h2>
      <vue-editor
        :editor-toolbar="customToolbar"
        v-model="details.additional_notes"
      ></vue-editor>
    </div>
    <!-- <div class="w-full mt-4">
      <div class="w-full border rounded-md ">
        <h2 class="text-gray-700 px-4 text-sm py-2 flex">
          <span class="text-red-500 "
            >Live <i class="mdi mdi-access-point"></i
          ></span>
          <span class="flex-grow order-first"
            >Aggiungi un link <i class="mdi mdi-link mdi-rotate-135"></i
          ></span>
        </h2>
        <div class="border-t bg-gray-100 py-2 px-4">
          <input class="bg-transparent" />
        </div>
      </div>
    </div> -->
    <!-- <div class="w-full my-4 flex">
      <div class="w-1/4 mr-4">
        <q-button
          class="w-full"
          :cta="true"
          @click="createProduct"
          :loading="isCreating"
        >
          Pubblica prodotto
        </q-button>
      </div>
      <button
        class="py-3 px-8 rounded-md text-red-500 border border-red-500"
        @click="resetModel"
      >
        Reset
      </button>
      <p>{{ creatingStatus }}</p>
    </div> -->
  </div>
</template>

<script>
import debounce from "lodash/debounce";
import { VueEditor } from "vue2-editor";
// import { API_URL } from "@/app-axios";
import { v4 as uuidv4 } from "uuid";
import Axios from "axios";
import { mapActions, mapGetters, mapMutations, mapState } from "vuex";
// import QFab from "@/_components/QFab";

export default {
  props: {
    productModel: {
      type: Object,
      default: () => {}
    }
  },
  components: {
    VueEditor
    // QFab
  },
  data() {
    return {
      title: "",
      description: "",
      note: "",
      file: null,
      isCreating: false,
      creatingStatus: "",
      filePath: "img%2Fproduct%2F",
      customToolbar: [
        ["bold", "italic", "underline"],
        ["link"],
        ["code-block"]
      ],
      details: {
        additional_notes: ""
      }
    };
  },
  computed: {
    ...mapState("products", ["products"]),
    ...mapGetters("products", ["companyId"]),
    s_product_name: {
      get() {
        return this.productModel.product_name;
      },
      set(v) {
        this.SET_PRODUCT_NAME(v);
      }
    },
    s_product_description: {
      get() {
        return this.productModel.product_description;
      },
      set(v) {
        this.SET_PRODUCT_DESCRIPTION(v);
      }
    },
    s_service_start_date: {
      get() {
        return this.productModel.service_start_date;
      },
      set(v) {
        this.SET_SERVICE_START_DATE(v);
      }
    },
    s_product_price: {
      get() {
        return this.$options.filters.price(this.productModel.single_unit_price);
      },
      set(v) {
        this.SET_PRICE(parseFloat(v));
      }
    }
  },
  watch: {
    productModel: {
      //debounce(this.update(), 500),
      handler: debounce(function(n) {
        console.log("Bounce ", n, this);
        this.$emit("update", n);
      }, 500),
      deep: true
    }
  },
  methods: {
    ...mapMutations("products", [
      "SET_COMPANY_ID",
      "SET_PRODUCT_PHOTO",
      "SET_COMPANY_ID",
      "SET_PRODUCT_NAME",
      "SET_PRICE",
      "SET_PRODUCT_DESCRIPTION",
      "SET_PUBLISHED",
      "SET_SERVICE_START_DATE"
    ]),
    ...mapActions("products", ["publishProduct", "resetModel"]),
    update(e) {
      console.log("Update Watch ", e);
    },
    async uploadProduct(e) {
      // console.log("CARICO FOTO PODOTTO", e.target.files[0]);
      this.file = e.target.files[0];
      try {
        let filename = this.file.name.split(".");
        let ext = filename[filename.length - 1];
        let options = {
          url: `${process.env.API_URL}/uploads/${
            this.filePath
          }${uuidv4()}.${ext}`,
          method: "PUT",
          headers: {
            "Content-Type": this.file.type
          },
          data: this.file
        };
        console.log("Options ", options);
        let res = await Axios(options);
        setTimeout(() => {
          this.SET_PRODUCT_PHOTO(res.data.url);
        }, 3500);
        console.log("Prodotto creato ", res);
      } catch (error) {
        console.log("Errore Upload photo", JSON.parse(JSON.stringify(error)));
      }
    },
    async createProduct() {
      try {
        this.isCreating = true;
        let created = await this.publishProduct();
        console.log("Created prod ", created);
        if (created.status == 200) {
          this.creatingStatus = "Prodotto creato con successo";
          this.resetModel();
        }
      } catch (error) {
        console.log("Errore nella creazione del prodotto", error);
        this.creatingStatus = "Errore nella creazione del prodotto";
      } finally {
        setTimeout(() => {
          this.creatingStatus = "";
        }, 5000);
        this.isCreating = false;
      }
    }
  }
};
</script>

<style>
@import "~vue2-editor/dist/vue2-editor.css";
</style>
